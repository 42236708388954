@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,200,300,400,500,600,700,800,900|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

// BODY
body {
	color: #111;
	font-size: 16px;
	font-family: 'Roboto', sans-serif;
	text-align: center;
	line-height: 1.528571429;
	padding: 0px;
	margin: 0;
}

// LINKS
body a {
	color: #a6a5a5;
	text-decoration: none;
	transition: 1s;
}

body a:hover,
body a:focus {
	text-decoration: underline;
}

// LINKED IMAGES
a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

a img:hover {
	opacity: 0.8
}

// font-family: 'Roboto Slab', serif;

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 42px;
	margin: 0 0 20px 0;
	color: #191c51;
	line-height: 1.2;
}

h2 {
	font-size: 30px;
	margin: 25px 0 20px 0;
	color: #00aec7;
	line-height: 1.3;
}

h3 {
	font-size: 15px;
	margin: 25px 0 10px 0;
	padding: 5px 10px;
	background: #253577;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

// PARAGRAPHS AND LIST ITEMS
p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

// FORMS
form {
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
	*margin-left: -7px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
	/* 4 */
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
	*height: 13px;
	*width: 13px;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	resize: vertical;
	overflow: auto;
	vertical-align: top;
}

label {
	float: left;
	margin: 10px 0;
	width: 90px;
	display: inline;
}

form .formfield {
	margin: 10px;
	float: left;
	display: inline;
	width: 200px;
	border: 1px solid #CCC;
	padding: 2px;
}

form div {
	margin: 15px 0;
	display: block;
	clear: left;
	vertical-align: top;
}

form div input[type="text"],
form div textarea,
form span textarea,
form div input[type="textarea"],
form div input[type="password"] {
	border: 1px solid #CCC;
	padding: 4px;
	width: 300px;
	margin: 0;
	background-color: #EFEFEF;
}

input[type="text"]:focus,
form div textarea:focus,
form span textarea:focus,
form div input[type="textarea"]:focus,
form div input[type="password"]:focus {
	border-color: #000;
}

form div span.label label,
form div span.label,
span.spacer,
span.multiple span {
	width: 180px;
	float: left;
	margin: 0 0 0 0;
}

form div span.multiple {
	float: left;
}

form div span.button {
	padding-left: 180px;
	background: transparent;
	border: 0;
}

form div span.button input {
	background: #666;
	color: #FFF;
	margin: 0;
	padding: 8px 10px;
	font-weight: bold;
	border: 0;
}

form div span.button input:hover {
	background: #888;
}

form div.clear {
	clear: both;
	padding-top: 5px;
}

// -----------------------------------//
// IE WARNING
// -----------------------------------//

#ie-warning {
	width: 100%;
	background: #b20000;
	color: #fff;
	overflow: hidden;
	padding: 0 0 10px 0;
	transition: 1s;
	display: block
}

#ie-warning p {
	margin: 5px auto;
}

#ie-warning a {
	color: #fff;
	text-decoration: underline;
	cursor: pointer;
}


// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	height: 140px;
	background: rgba(25, 28, 81, 0.8);
	border-bottom: 2px solid #fff;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
}

img#logo {
	float: left;
	margin: 20px 0 0 31px;
	animation: fadeInLeft 3s 1;
}

.trust-tab {
	background: rgba(0, 0, 0, 0.16) url(/i/design/trust-tab.png) no-repeat bottom center;
	position: absolute;
	right: 33px;
	top: -240px;
	height: 300px;
	width: 140px;
	cursor: pointer;
	transition: 1s;
	z-index: 9999999;
	padding: 0 10px;
	animation: fadeInRight 3s 1;

	&:before {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		height: 6px;
		width: 16px;
		background: url(/i/design/trust-ic.png) no-repeat;
	}

	h2 {
		color: #fff;
		font-size: 20px
	}

	p {
		color: #fff;
		font-size: 13px;
	}

	a {
		color: #fff;
	}
}

.trust-tab-extended {
	top: 0;
	transition: 1s;
	background: rgba(0, 0, 0, 1) url(/i/design/trust-tab.png) no-repeat bottom center;

	&:before {
		transform: rotate(180deg);
	}
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

.social-dock {
	position: absolute;
	top: 30px;
	right: 230px;

	@media only screen and (max-width: 990px) {
		@include posreset;
	}

	li {
		display: inline-block;
		vertical-align: middle;
		background: none;
		padding: 0;
		margin: 0 10px 0 0;
	}
}

#FS {
	// position: absolute;
	// right: 280px;
	// top: 42px;
	// z-index: 9999;
	// font-size: 16px;
}

#FS input {
	outline: none;
	color: #fff;
}

#FS input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

#FS input::-webkit-search-decoration,
#FS input::-webkit-search-cancel-button {
	display: none;
}

#FS input[type=search] {
	color: transparent;
	cursor: pointer;
	background: url(/i/design/search-ic.png) no-repeat 0 center;
	padding: 0px;
	width: 32px;
	height: 32px;
	transition: all .5s;
}

#FS input[type=search]:focus {
	width: 200px;
	background: #000;
	padding: 5px 0 5px 12px;
	color: #fff;
	cursor: auto;
	font-weight: 400;
}

#FS input[type=search]:focus:-moz-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input[type=search]:focus::-webkit-input-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input:-moz-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input::-webkit-input-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}


// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: #191c51;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

// .goog-te-gadget .goog-te-combo {
// 	margin: 0px !important;
// 	height: 30px;
// 	width: 30px;
// }

// #google_translate_element {
// 	opacity: 0;
// }

// .translate {
// 	position: absolute;
// 	right: 232px;
// 	top: 42px;
// 	overflow: hidden;
// 	height: 30px;
// 	width: 30px;
// 	background: url(/i/design/translate-ic.png) no-repeat;
// }

$translate-size: 30px;

.g-tran,
.translate {
	@include box($translate-size);

	img {
		pointer-events: none;
		user-select: none;
		position: relative;
		top: -$translate-size;
	}
}

#google_translate_element {
	opacity: 0;
}

.translate {
	overflow: hidden;
	z-index: 99;
	background: url(/i/design/translate-ic.png) no-repeat;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

body.body_6168 #mainholder {
	padding: 0;
	background: #fff;
}

#mainholder {
	width: 100%;
	background: #fff url(/i/design/logo-bg.png) no-repeat bottom 30px right 30px;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}

// -----------------------------------//
// MISCELLANEOUS
// -----------------------------------//

#home-panels {
	width: 100%;
	padding: 10px 0;
	// height: 1040px;
	background: #fff;
}

.home-panel {
	width: calc(33.5% - 10px);
	float: left;
	display: inline-block;
	height: 340px;
	margin: 0 10px 10px 0;
	position: relative;
	font-family: 'Roboto Slab', serif;
	overflow: hidden;

	h2 {
		color: #fff;
		font-size: clamp(20px, 2vw, 40px);
		background: rgba(166, 165, 165, 0.6);
		height: 100px;
		line-height: 100px;
		margin: 0 auto;
		text-align: center;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		letter-spacing: 0.03em;
		transition: 1s;
		font-weight: 400;
	}

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:hover {
		h2 {
			height: 340px;
			line-height: 340px;
			transition: 1s;
		}

		img {
			opacity: 1;
		}
	}

	&:nth-of-type(even) {
		h2 {
			background: rgba(0, 30, 78, 0.6);
		}
	}

	&:nth-of-type(3) {
		margin: 0;
	}

	&:nth-of-type(6) {
		margin: 0;
	}

	&:nth-of-type(9) {
		margin: 0;
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 930px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;
	box-shadow: inset 0 0 0 1px #191c51;
	background: #fff;

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {
		font-weight: 700;
		text-transform: uppercase;
	}

	h2 {
		font-weight: 700;
	}
}

//Content 2
.Content2Column {
	width: 650px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;
	box-shadow: inset 0 0 0 1px #191c51;
	background: #fff;

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {
		font-weight: 700;
		text-transform: uppercase;
	}

	h2 {
		font-weight: 700;
	}

	tr,
	td {
		border: 1px solid #000 !important;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: right;
}

.ColumnRight {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: right;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 30px 0 0 0;
	padding: 0;
	text-align: center;
}

.SideHeading:first-child {
	margin-top: 0;
}

// Heading
.SideHeading h2 {
	display: block;
	text-align: center;
	font-size: 24px;
	padding: 20px 0;
	margin: 0;
}

// List
ul.SideList {
	margin: 0 0 0 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0 0 0 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 5px 10px;
	color: #333;
	transition: 1s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	background: #999;
	color: #FFF;
	transition: 1s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;

	&:hover {
		background-color: #999;
	}
}


// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	width: 55px;
	height: 55px;
	text-align: center;
	background: #000;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 130%;

	span {
		display: block;
		font-weight: bold;
		font-size: 24px;
		margin: 10px 0 0 0;
	}
}

ul.SideList p.title {
	float: right;
	width: 75%;
	font-size: 15px;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 15px;
	padding: 0px;
	transition: 1s;

	&:hover {
		transition: 1s;
		text-decoration: none;
	}
}

//Related pages
#SideHeadingPages {
	overflow: hidden;
	background: #191c51;

	h2 {
		color: #fff;
		width: 80%;
		margin: auto;
	}

	ul.SideList {
		padding: 20px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 16px;
				color: #fff;

				&:hover {
					background-color: #fff;
					color: #191c51;
				}
			}
		}
	}

}


// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}


// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 13px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}


// -----------------------------------//
// FOOTER
// -----------------------------------//

#map {
	width: 100%;
	height: 190px;
	overflow: hidden;
	background: #b0b3b4;
	position: relative;

	.map-pin {
		@include center;
		transition: .3s;
		opacity: 1;
		display: block;
		@include box(135px);

		&:hover {
			transform: translate(-50%, -45%);
		}
	}
}

.scroll-up {
	position: absolute;
	right: 30px;
	top: 0;
	bottom: 0;
	margin: auto;
	height: 30px;
	width: 30px;
	background: url(/i/design/scroll-up.png) no-repeat
}

footer {
	clear: both;
	padding: 20px 0 70px;
	overflow: hidden;
	width: 100%;
	background: #191c51;
	position: relative;

	@media only screen and (max-width: 990px) {
		padding: 0;
	}
}

footer>.wrapper {
	color: #fff;
	text-align: left;
	overflow: hidden;
	position: relative;
}

footer>.wrapper p {
	font-size: 18px;
	font-weight: 300;
	line-height: 1.333;
	position: relative;
}

footer>.wrapper a {
	color: #fff;
}


.school-years {
	overflow: hidden;
	height: 100%;
	text-align: center;

	h2 {
		color: #fff;
		font-size: 30px;
		margin: 28px 0 28px 40px;
		font-weight: 700;
	}

	p {
		display: inline-block;
		vertical-align: middle;
		margin: 0;
		box-sizing: border-box;
		text-align: center;
		position: relative;

		@media only screen and (max-width: 990px) {
			display: block;
		}

	}

	p.address {
		width: 30%;
		padding: 0 0 0 50px;
		box-sizing: border-box;
		text-align: left;

		@media only screen and (max-width: 990px) {
			width: 100%;
			padding: 0;
			text-align: center;
		}


		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 5px;
			height: 30px;
			width: 30px;
			background: url(/i/design/location-ic.png) no-repeat;

			@media only screen and (max-width: 990px) {
				display: none;
			}
		}
	}

	p.telephone {
		width: 28%;

		@media only screen and (max-width: 990px) {
			width: 100%;
		}

		&:before {
			content: '';
			position: absolute;
			left: 30px;
			top: -5px;
			height: 30px;
			width: 30px;
			background: url(/i/design/telephone-ic.png) no-repeat;

			@media only screen and (max-width: 990px) {
				display: none;
			}
		}
	}

	p.email-footer {
		width: 32%;

		@media only screen and (max-width: 990px) {
			width: 100%;
		}

		&:before {
			content: '';
			position: absolute;
			left: -10px;
			top: -5px;
			height: 30px;
			width: 30px;
			background: url(/i/design/email-ic.png) no-repeat;

			@media only screen and (max-width: 990px) {
				display: none;
			}
		}
	}
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	height: 32px;
	width: 100%;
	line-height: 32px;
	background: #191c51;
	overflow: hidden;

	p {
		font-size: 12px;
		color: #fff;
		float: left;
		margin: 0;
		font-weight: 400;
		letter-spacing: 0.03em;
	}

	a {
		color: #b4b4b4;
	}

	#credit {
		float: right;
	}
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #000;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	font-family: sans-serif;
	text-align: center;
}

$blockquote-background-color: #EFEFEF;
$blockquote-border-color: #EBEBEB;
$blockquote-color: #000;
$blockquote-speech-mark-color: #333;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content {
	background: none;
}


// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #D4C790;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
	color: #6d6647;
	text-align: center;
	background: #FAF4AF;
}

#browserblast a {
	color: green;
	text-decoration: underline;
}

.unsupported-browser {
	margin-top: 41px;
}

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile {
	width: 100%;
	margin: 0;
	padding: 20px 0;
	background: #EFEFEF;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
	text-align: center;
	color: #777;
	font-family: sans-serif;
	font-size: 12px;
	display: block;
	clear: both;
}

#switch-mobile a {
	text-decoration: underline;
}