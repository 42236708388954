// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_6168 #slider {
  height: calc(100vh - 100px);
  max-height: 1080px;
  min-height: 550px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 990px) {
    height: 500px;
    min-height: 500px;
  }

  &:after {
    @include before(100%, 300px);
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
    opacity: .5;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  min-height: 500px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    height: 450px;
    min-height: 450px;
  }
}

.backstretch {
  position: relative;

  img {
    top: 0px !important;
    animation: scale 25s ease forwards infinite;
  }

  @keyframes scale {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.25);
    }
  }
}

.body_6168 .backstretch {

  @media only screen and (min-width: 990px) {
    opacity: 0;
    animation: appear 2s ease forwards 1;

    @keyframes appear {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
}


.scroll-down {
  position: absolute;
  left: 0;
  bottom: 80px;
  right: 0;
  margin: auto;
  width: 37px;
  height: 14px;
  background: url(/i/design/scroll-down.png) no-repeat;
  animation: fadeInRight 3s 1;
  z-index: 99;
}


.right-side-expand {
  position: absolute;
  right: 0;
  top: 180px;
  margin: auto;

  .link-1 {
    width: 230px;
    height: 50px;
    line-height: 50px;
    background: url(/i/design/links-bg.png) no-repeat;
    display: block;
    margin: 2px 0;
    text-align: left;
    color: #001e4e;
    font-size: 17px;
    padding: 0;
    position: relative;
    right: -180px;
    text-indent: 65px;
    animation: fadeInRight 3s 1;

    // &:nth-of-type(1) {
    //   img {
    //     left: 15px;
    //   }
    // }

    img {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      max-width: 32px;
    }

    &:hover {
      background-color: #fff;
      text-decoration: none;
      right: 0;
    }
  }
}