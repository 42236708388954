// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//
$slideOut-panel-bg: #f7f7f7;

$link-bg-colour: rgba(#fff, .5);
$link-bg-colour-hover: #fff;
$links-colour: #001e4e;

$slide-click-colour: #001e4e;

$mobile-tab-color: #b0b3b4;

/* Full container */
#slideOut {
	position: fixed;
	width: auto;
	height: auto;
	min-height: 200px;
	top: 50%;
	left: -250px;
	padding: 0px;
	z-index: 99999999;
	font-family: 'Roboto Slab', serif;
}


/* Click to slide */
#slideClick {
	float: right;
	height: 100%;
	width: 50px;
	cursor: pointer;
	position: relative;
	background: rgba(255, 255, 255, 0.8);
	margin: 0 0 0 10px;

	span {
		width: 160px;
		height: 70px;
		position: absolute;
		top: 55%;
		left: 50%;
		font-size: 32px;
		color: $slide-click-colour;
		white-space: nowrap;
		transform: rotate(-90deg);
		margin-left: -73px;
		margin-top: -37px;

		&:before {
			content: "";
			width: 9px;
			height: 24px;
			background: url(/i/design/arrow-open-close.png) no-repeat center center;
			position: absolute;
			left: 50%;
			top: 75%;
			transform: rotate(90deg);
		}
	}
}

/* Content links */
#slideContent {
	width: auto;
	height: 100%;
	padding: 0;
	margin: 0;
	float: left;
	color: #EFEFEF;
	text-align: center;
}


/* Box with links  */
ul.quick-links {
	width: 240px;
	display: block;
	text-align: center;
	overflow: hidden;

	li {
		background: none;
		margin: 5px 0;
		padding: 0;
		display: block;

		a {
			background: $link-bg-colour url(/i/design/arrow-quick-links.png) no-repeat left 15px center;
			color: $links-colour;
			font-size: 18px;
			display: block;
			text-align: left;
			padding: 15px 10px 15px 45px;
			transition: 1s;
			font-family: 'Roboto', sans-serif;

			&:hover {
				background-color: $link-bg-colour-hover;
				text-decoration: none;
				color: #001e4e;
				transition: 1s;
			}
		}
	}
}

@media only screen and (min-width: 750px) {

	#slideContent {
		display: table !important;
	}

	ul.quick-links {
		vertical-align: middle;
		display: table-cell;
	}

}

/* Change the arrow  */
.open span::before {
	transform: rotate(-90deg) !important;
}

.flip {
	display: none;
}

@media only screen and (max-width: 750px) {
	#slideOut {
		position: relative;
		top: 44px;
		min-height: 0px;
		left: 0;
		padding: 0px 3% 0 2%;
		overflow: visible;
		height: auto !important;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
		border-radius: 0 0 10px 10px;
		margin: 0 5% !important;
		background: $mobile-tab-color;
	}

	#slideClick {
		display: none;

		span {
			transform: rotate(0);
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}

	#slideContent {
		float: none;
	}

	ul.quick-links {
		width: 100%;

		li {
			a {
				padding: 6px 0 6px 0;
				text-align: center;
				background-position: 8% 10px;
			}
		}
	}


	.flip {
		z-index: 800;
		margin: 0;
		padding: 0 0 5px 0;
		cursor: pointer;
		font-size: 16px;
		color: #fff;
		display: block;

		img {
			display: block;
			margin: 0 auto;
			transform: rotate(90deg);
		}
	}

	.open img {
		transform: rotate(-90deg);
	}
}