// CMS UI Styles
@import 'c_ui_styles';
@import 'mixins';

@import 'c_reset';
@import 'c_site';
@import 'c_default';
@import 'c_insidepages';
@import 'c_slider';
@import 'c_cmsstyles';
@import 'c_animate';

@import 'backstretch-captions';
@import 'welcome';

@import 'c_meanmenu';
@import 'c_respond';

//Quick links widget
@import 'c_quicklinks';