// -----------------------------------//
// BACKSTRETCH CAPTIONS
// -----------------------------------//

// ---- BASED ON ---- //

// duration: 5000
// , fade: 550

.caption-outer {
 position: absolute;
 left: 0;
 right: 0;
 bottom: 120px;
 margin: 0 auto;
 max-width: 95%;
 text-align: center;
 z-index: 99;
 opacity: 0;
 animation: appear 2s ease forwards 1;

 @media only screen and (max-width: 990px) {
  // @include posreset;
 }

 p {
  color: #fff;
  line-height: 1;
  font-size: clamp(25px, 3vw, 80px);
  font-family: 'Roboto Slab', serif;

  span {
   display: block;
   font-size: 80%;
  }
 }
}

@keyframes appear {
 from {
  opacity: 0;
 }

 to {
  opacity: 1;
 }
}