#welcome {
	min-height: 586px;
	padding: 0 0 30px 0;
	background: url(/i/design/welcome-bg.png) no-repeat center;
	// background-size: cover;
	overflow: hidden;
	width: 100%;

	.wrapper {
		overflow: hidden;
		min-height: 616px;
		position: relative;
	}

	h2 {
		text-align: center;
		font-size: 40px;
		color: #00aec7;
		font-family: 'Roboto Slab', serif;
		margin: 40px auto 10px;

		span {
			display: block;
			font-size: 24px;
			color: #161741;
		}
	}

	#welcome-img {
		float: left;
		margin-top: 20px;
	}

	p {
		float: right;
		width: 565px;
		line-height: 1.8;
		font-size: 18px;
		color: #414042;
		font-weight: 300;

		&:first-of-type {
			margin-top: 50px;
		}
	}

	a.more {
		position: absolute;
		right: 0;
		bottom: 50px;
		display: block;
		font-size: 14px;
		padding: 0px;
		transition: 1s;
		width: 240px;
		height: 43px;
		line-height: 43px;
		color: #fff;
		background: #00aec7;
		text-align: center;
		font-family: 'Roboto Slab', serif;
		text-transform: uppercase;
		border-radius: 4px;
		margin: 5px auto;
		font-weight: 600;

		&:hover {
			transition: 1s;
			text-decoration: none;
			background: #fff;
			color: #00aec7;
			box-shadow: inset 0 0 0 1px #00aec7
		}
	}

}